body {
  background-color: #041129; 
  background-image: url('./images/bg_bluePipes.png');
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
