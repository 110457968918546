$sidebar-bg-color: #0a1934 !default;
$sidebar-color: #adadad !default;
$sidebar-width: 210px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #ffffff !default;
$submenu-bg-color: #2b2b2b !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: transparent !default;
$icon-size: 35px !default;
$submenu-indent: 24px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 744px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";

@keyframes swing {
  // Zero's out the default animation
  0% {
    top: 0px;
  }
}
