body {
  background-color: #041129; 
}

.content {
  padding: 12px;
}

/* .wrapper {
  position: absolute;
  width: 100%;
}

.navbar {
  position: absolute;
  width: 100%;
  z-index: 1500;
} */

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #1A2740 #06112a;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: clear;
}

*::-webkit-scrollbar-thumb {
  background-color: #1a2740;
  border-radius: 20px;
  border: 1px solid #06112a;
}

// Without this UI becomes unresponsive when making code changes
// With this Dashboard display is not interactive
// This should be on for development, off for prod
// On prod deploy always ensure Dashboard is interactive


//-----------HOW CAN WE GET RID OF THIS?
//-----------This will get removed with Graphena?
iframe {
  pointer-events:  none;
}

// Dropdown placeholder styling
#inputID::placeholder {
  color: #4c628c;
  opacity: 1;
  font-weight: 300;
}

// Dropdown placeholder input text styling
#inputID {
  font-weight: 300;
}

// Makes the tables have a static header that doesn't scroll away
.sticky {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
}
